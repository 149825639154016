export const jsonData = {
  code: 200,
  message: 'OK',
  data: {
    browser_list: [
      {
        browser_name: 'Samsung Internet Browser',
        icon_url:
          'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo/images/icon-samsung.png',
        last_record_date: 1700178899,
      },
      {
        browser_name: 'Google Chrome',
        icon_url:
          'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo/images/icon-google-chrome.png',
        last_record_date: 1699979399,
      },
      {
        browser_name: 'Microsoft Edge',
        icon_url:
          'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo/images/icon-microsoft-edge.png',
        last_record_date: 1699499459,
      },
    ],
  },
};
