export const jsonDataSamsung = {
  code: 200,
  message: 'OK',
  data: {
    history_records: [
      {
        browser_name: 'Samsung Internet Browser',
        date: 1700178899,
        limit_type: 2,
        url: 'https://freespin.com/',
      },
      {
        browser_name: 'Samsung Internet Browser',
        date: 1699303739,
        limit_type: 0,
        url: 'https://www.nba.com/',
      },
      {
        browser_name: 'Samsung Internet Browser',
        date: 1698196800,
        limit_type: 0,
        url: 'ai homework helper',
      },
      {
        browser_name: 'Samsung Internet Browser',
        date: 1697747699,
        limit_type: 2,
        url: 'https://www.liveleak.com/',
      },
    ],
    total: 4,
    update_time: 1700116734,
  },
};

export const jsonDataGoogle = {
  code: 200,
  message: 'OK',
  data: {
    history_records: [
      {
        browser_name: 'Google Chrome',
        date: 1699979399,
        limit_type: 2,
        url: 'https://www.stormfront.org/',
      },
      {
        browser_name: 'Google Chrome',
        date: 1699300199,
        limit_type: 1,
        url: 'https://www.pornhub.com/',
      },
      {
        browser_name: 'Google Chrome',
        date: 1699210200,
        limit_type: 1,
        url: 'https://www.betonline.ag/',
      },
      {
        browser_name: 'Google Chrome',
        date: 1699037400,
        limit_type: 2,
        url: 'https://tinder.com/',
      },
      {
        browser_name: 'Google Chrome',
        date: 1698766200,
        limit_type: 2,
        url: 'https://www.omegle.com/',
      },
      {
        browser_name: 'Google Chrome',
        date: 1696542299,
        limit_type: 0,
        search_query: 'fitness advice',
        url: 'https://www.forbes.com/health/fitness/expert-fitness-advice/',
      },
    ],
    total: 6,
    update_time: 1700116734,
  },
};

export const jsonDataEdge = {
  code: 200,
  message: 'OK',
  data: {
    history_records: [
      {
        browser_name: 'Microsoft Edge',
        date: 1699499459,
        limit_type: 0,
        url: 'https://www.reddit.com/r/videogames/',
      },
      {
        browser_name: 'Microsoft Edge',
        date: 1696699979,
        limit_type: 0,
        url: 'https://www.tiktok.com/tag/trending',
      },
    ],
    total: 2,
    update_time: 1700116734,
  },
};
